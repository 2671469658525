import React from "react";
import Routes from "components/routing/Routes";
import ScrollToTop from '../routing/ScrollToTop';
import { Toaster } from "react-hot-toast";

function App() {
  return (
      <>
        <ScrollToTop/>
        <Toaster position="bottom-center" />
        <Routes/>
      </>
  );
}

export default App;

import React from 'react';
import Content from 'components/layout/content/Content';
import styling from './Travel.module.scss';
import Header from 'components/UI/header/Header';
import Information from './Information';
import SectionTravel from './sectiontravel/SectionTravel';
import { Trans } from 'react-i18next';
import Divider from '../../UI/divider/Divider';

import HeaderImg from 'media/images/travel.webp';


const Travel = () => {
  return (
    <Content>
      <Header
        title={'TRAVEL'}
        img={HeaderImg}
      />

      {/* Index */}
      <div className={styling.container}>
        <span className={styling.index}>
          {Information.map((info, index) => {
            return (
              <div className={styling.indexElements} key={index}>
                <a href={`#${info.id}`}>
                  <Trans>{info.section}</Trans>
                </a>
                {index !== Information.length - 1 && (
                  <span className={styling.separator}>|</span>
                )}
              </div>
            );
          })}
        </span>

        <Divider />

        <div className={styling.section}>
          {Information.map((info) => {
            return (
              <SectionTravel
                key={info.id}
                id={info.id}
                section={info.section}
                title={info.title}
                text={info.text}
                extraText={info.extraText}
                isBulletPoint={info.isBulletPoint}
              />
            );
          })}
        </div>
      </div>
    </Content>
  );
};

export default Travel;

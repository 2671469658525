const Questions = [
  {
    id: 'dressCode',
    question: 'WHAT_IS_THE_DRESS_CODE',
    answer: 'WHAT_IS_THE_DRESS_CODE_ANSWER',
  },
  {
    id: 'rsvp',
    question: 'WHAT_IF_I_DONT_RSVP',
    answer: 'WHAT_IF_I_DONT_RSVP_ANSWER',
  },
  {
    id: 'plusOne',
    question: 'CAN_I_BRING_A_PLUS_ONE',
    answer: 'CAN_I_BRING_A_PLUS_ONE_ANSWER',
  },
  {
    id: 'arriveTime',
    question: 'WHEN_SHOULD_I_ARRIVE',
    answer: 'WHEN_SHOULD_I_ARRIVE_ANSWER',
  },
  {
    id: 'kids',
    question: 'ARE_KIDS_INVITED',
    answer: 'ARE_KIDS_INVITED_ANSWER',
  },
  {
    id: 'honeyMoon',
    question: 'HOW_CAN_WE_CONTRIBUTE_TO_YOUR_HONEYMOON_FUND',
    answer: 'HOW_CAN_WE_CONTRIBUTE_TO_YOUR_HONEYMOON_FUND_ANSWER'
  },
  {
    id: 'parking',
    question: 'WHERE_CAN_I_PARK',
    answer: 'WHERE_CAN_I_PARK_ANSWER',
  },
  {
    id: 'transport',
    question: 'WILL_TRANSPORT_BE_OFFERED',
    answer: 'WILL_TRANSPORT_BE_OFFERED_ANSWER',
  },
];

export default Questions;

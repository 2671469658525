import React from 'react';
import img from 'media/leaf-divider.svg';

import styling from './Divider.module.scss';

const Divider = () => {
    return (
        <div className={styling.divider}>
            <div className={styling.line}></div>
            <img
                className={styling.image}
                src={img}
                alt={'leaf'}
            />
            <div className={styling.line}></div>
        </div>
    )
};

export default Divider;
import React from "react";

import styling from "./Content.module.scss";

const Content = ({ children, containerStyle }) => {
  return (
    <div className={containerStyle ? containerStyle : styling.container}>
      {children}
    </div>
  );
};

export default Content;

import { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem('auth');
    return storedAuth
      ? JSON.parse(storedAuth)
      : {
          user: null,
          token: '',
          expirationDate: '',
        };
  });

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);

  // axios config for all requests
  if (process.env.REACT_APP_ENV === 'dev') {
    // Local environment
    axios.defaults.baseURL = process.env.REACT_APP_API;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] =
      'http://localhost:3000';
  } else {
    // Production environment
    axios.defaults.baseURL = process.env.REACT_APP_URL;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] =
      'https://www.andreasandsonia.com';
  }
  // default settings for axios
  axios.defaults.headers.common['Authorization'] = auth?.token;
  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
  axios.defaults.headers.common['Access-Control-Allow-Methods'] =
    'GET,PUT,POST,DELETE,PATCH,OPTIONS';
  axios.defaults.headers.common['Access-Control-Allow-Headers'] =
    'Origin, X-Requested-With, Content-Type, Accept, X-Auth-Token, Authorization';

  axios.defaults.withCredentials = true; // Ensure cookies are sent with requests

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };

import React from 'react';
import { Trans } from 'react-i18next';

import styling from './Button.module.scss';

const Button = ({ buttonText, onClick, classStyle }) => {
  return (
    <div
      className={`${classStyle && classStyle} ${styling.container}`}
      onClick={onClick && onClick}
    >
      <p className={styling.buttonText}>
        <Trans>{buttonText}</Trans>
      </p>
    </div>
  );
};

export default Button;

import React, { useState } from "react";
import { Trans } from "react-i18next";
import { NavLink } from "react-router-dom";

import LanguageSwitcher from 'components/shared/languageSwitcher/LanguageSwitcher';
import { IconBaselineDensityMedium } from "@tabler/icons-react";

import styling from "./TopBar.module.scss";

const TopBar = ({ userType, currentLanguage, setCurrentLanguage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const links = [
    {
      label: "HOME",
      route: "/",
      user: ['admin', 'en', 'es']
    },
    {
      label: "DRINKS",
      route: "/welcome-drinks",
      user: ['admin', 'en']
    },
    {
      label: "TIMELINE",
      route: "/timeline",
      user: ['admin', 'en', 'es']
    },
    {
      label: "TRAVEL",
      route: "/travel",
      user: ['admin', 'en', 'es']
    },
    {
      label: "RSVP",
      route: "/rsvp",
      user: ['admin', 'en', 'es']
    },
    {
      label: "FAQs",
      route: "/faqs",
      user: ['admin', 'en', 'es']
    },
    {
      label: "GUEST_LIST",
      route: "/guestList",
      user: ['admin']
    }
  ];

  const updatedLinks = links.filter(tab => tab?.user.includes(userType));

  return (
    <div className={styling.main}>
      <div className={styling.linkContainer}>
        {updatedLinks.map((link, index) => {
          return (
            <NavLink
              key={index}
              className={styling.links}
              style={({ isActive }) => ({
                borderBottom: isActive ? "1px solid var(--color-6-800)" : "",
              })}
              to={link.route}
            >
              <Trans>{link.label}</Trans>
            </NavLink>
          );
        })}
      </div>

      {/* Burger Menu */}
      <div
        className={styling.burgerMenu}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <IconBaselineDensityMedium color={"var(--color-2-0)"} />
      </div>

      <LanguageSwitcher
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
      />

      {/* Menu Items for Mobile */}
      {isMenuOpen && (
        <div className={styling.mobileMenu}>
          {updatedLinks.map((link, index) => (
            <NavLink
              key={index}
              className={styling.mobileLinks}
              to={link.route}
              onClick={() => setIsMenuOpen(false)} // Close menu on link click
            >
              <Trans>{link.label}</Trans>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default TopBar;

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingGIF from "../../media/loading.gif";
import styling from "./Loading.module.scss";

const Loading = ({ path = "" }) => {
  // Update to show login popup
  // state
  const [count, setCount] = useState(3);
  // hook
  const navigate = useNavigate();

  useEffect(() => {
    // setInterval: run a function every x seconds
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount);
    }, 100);
    //  redirect once count is equal to 0
    count === 0 && navigate(`/login`);
    // cleanup
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [count]);

  return (
    <div className={styling.loading}>
      <img src={LoadingGIF} alt="Loading" className={styling.loadingImg} />
    </div>
  );
};

export { Loading };

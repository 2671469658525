const WeddingInfo = [
    {
        title: "TRANSPORT_CEREMONY",
        location: "TRANSPORT_CEREMONY_LOCATION",
        secondLocation: "TRANSPORT_CEREMONY_LOCATION_2",
        time: "3:15 PM",
        secondTime: "3:30 PM",
        extra: "",
        googleMapsAddress: "https://maps.app.goo.gl/nuvmngZj8mcAQakR6",
        secondGoogleMapsAddress: "https://www.google.com/maps/place/Aparcamiento+Rivas+Centro-+Entrada+al+Polideportivo/@40.356468,-3.5465506,20.29z/data=!4m6!3m5!1s0xd423b000708187d:0x1f6c285d58668fdb!8m2!3d40.3565202!4d-3.5460003!16s%2Fg%2F11vywh9rbt?entry=ttu",
        user: ['admin', 'en', 'es']
    },
    {
        title: "CEREMONY",
        location: "CEREMONY_LOCATION",
        time: "5:00 PM - 6:00 PM",
        extra: "",
        googleMapsAddress: "https://maps.app.goo.gl/7yRFsX4oYaKdNmrj9",
        user: ['admin', 'en', 'es']
    },
    {
        title: "RECEPTION",
        location: "RECEPTION_LOCATION",
        time: "6:45 PM - 8:30 PM",
        extra: "",
        googleMapsAddress: "https://maps.app.goo.gl/fAF1HSE4LS3e9xFY8",
        user: ['admin', 'en', 'es']
    },
    {
        title: "DINNER",
        location: "DINNER_LOCATION",
        time: "8:30 PM - 10:30 PM",
        extra: "",
        googleMapsAddress: "https://maps.app.goo.gl/fAF1HSE4LS3e9xFY8",
        user: ['admin', 'en', 'es']
    },
    {
        title: "PARTY",
        location: "PARTY_LOCATION",
        time: "11:00 PM - 4:00 AM",
        extra: "",
        googleMapsAddress: "https://maps.app.goo.gl/fAF1HSE4LS3e9xFY8",
        user: ['admin', 'en', 'es']
    },
    {
        title: "TRANSPORT_BACK",
        location: "TRANSPORT_BACK_LOCATION",
        time: "1:00 AM & 4:00 AM",
        user: ['admin', 'en', 'es']
    },
];

export { WeddingInfo };

import React from 'react';
import { Trans } from 'react-i18next';

import Content from 'components/layout/content/Content';
import Header from 'components/UI/header/Header';

import Divider from 'components/UI/divider/Divider';
import Section from './section/Section';

import HeaderImg from 'media/images/faqs.webp';

import Questions from './Questions';

import styling from './FAQ.module.scss';

const FAQ = () => {
    const auth = localStorage?.getItem('auth');
    const authJson = JSON.parse(auth);
    const user = authJson.user;

  return (
    <Content>
      <Header
        title="FAQs"
        img={HeaderImg}
      />

      <div className={styling.container}>
        <span className={styling.index}>
          {/* Questions header */}
          {Questions.map((question, index) => {
            return (
              <div className={styling.indexElements} key={index}>
                <a href={`#${question.id}`}>
                  <Trans>{question.question}</Trans>
                </a>
                {index !== Questions.length - 1 && (
                  <span className={styling.separator}>|</span>
                )}
              </div>
            );
          })}
        </span>

        <Divider />
          {/* Answers */}
        {Questions.map((question) => {
          return (
            <Section
              key={question.id}
              id={question.id}
              question={question.question}
              answer={question.answer}
              link={question?.link}
            />
          );
        })}
      </div>
    </Content>
  );
};

export default FAQ;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // init with resources
    resources: {
      en: {
        translations: {
          // Topbar
          HOME: 'HOME',
          DRINKS: 'WELCOME DRINKS',
          TIMELINE: 'TIMELINE',
          TRAVEL: 'TRAVEL',
          RSVP: 'RSVP',
          FAQs: 'FAQs',
          GUEST_LIST: 'GUEST LIST',

          // Login
          SEND: 'SEND',
          'Please enter your invitation code...':
            'Please enter your invitation code...',

          // Home
          SONIA_AND_ANDREAS: 'SONIA\n & \nANDREAS',
          WELCOME_PAGE_TITLE: 'Welcome to our Wedding',
          DATE: 'MADRID, ES \n•\n AUGUST 24, 2024',
          RSVP_LINK: 'RSVP',
          REGISTRY: 'REGISTRY',
          REGISTRY_LINK: 'To the registry',
          WELCOME_PAGE_FIRST_PARAGRAPH:
            ' We are eagerly anticipating celebrating with all our loved ones on\n' +
            'our special day! Your presence is the greatest gift of all.',
          WELCOME_PAGE_SECOND_PARAGRAPH:
            'For those who wish to contribute to our honeymoon adventure, we have set up a cash registry. Your generosity is greatly appreciated.',
          WELCOME_PAGE_TEXT:
            "We're so thrilled you've found your way to our wedding website! " +
            "We're excited to share our love story and all the details about our " +
            'upcoming celebration with you.',
          HOME_REGISTRY_TEXT:
            'We appreciate you all traveling to celebrate with us. ' +
            'Having you with us on our special day is the biggest present we could ask for. ' +
            'If you feel inclined to give us a gift, please find our bank account or our registry link below.',
            // 'Our registry link will be available soon.',
          BANK_ACCOUNT: '<strong>IBAN</strong>: LT52 3250 0216 3391 4662\n' +
              '<strong>BIC/SWIFT</strong>: REVOLT21 \n\n' +
              '<strong>MobilePay BOX</strong>: 1191NK \n',
          HOME_RSVP_TEXT:
            "Mark your calendars! We're getting married and can't wait to " +
            'celebrate with you! Please RSVP by July 15th. ' +
            "We're looking forward to sharing our special day with you.",

          // Timeline
          THE_DAY: 'The big day',
          THE_DAY_DATE: 'August 24th, 2024',
          PREVIOUS_DAY: 'Cheers to forever',
          PREVIOUS_DAY_DATE: 'Friday 23 of August, 2024',
          WELCOME_DRINKS: 'WELCOME DRINKS',
          DRESS_CODE: 'DRESS CODE',
          DRESS_CODE_DRINKS: 'Semi-formal',
          DRESS_CODE_WEDDING_WOMAN: 'Women: Long cocktail dress',
          DRESS_CODE_WEDDING_MAN: 'Men: Morning suit or dark suit',
          BOARD_INSPO: 'You can see some inspiration from spanish weddings <a href=\"https://www.pinterest.es/son96ariza/dress-code/" target=\"_blank\" rel=\"noreferrer noopener\">here</a>. More info in \"FAQs\"',
          RESERVE_THE_DATE: 'Reserve the date',
          TRANSPORT_CEREMONY: 'TO THE CEREMONY',
          PRE_WEDDING: 'PRE WEDDING',
          CEREMONY: 'CEREMONY',
          RECEPTION: 'RECEPTION',
          PRE_WEDDING_DATE: 'August 23, 2024',
          WEDDING_DATE: 'August 24, 2024',
          ADD_TO_CALENDAR: 'Add to calendar',
          VIEW_ON_MAPS: 'View on maps',
          DINNER: 'DINNER',
          PARTY: 'PARTY',
          TRANSPORT_BACK: 'THANKS FOR COMING',

          WELCOME_DRINKS_LOCATION: 'Gran Café El Espejo',
          TRANSPORT_CEREMONY_LOCATION: 'Buses will leave from Hotel H10 Puerta de Alcalá',
          CEREMONY_LOCATION: 'Church of San Pedro Apóstol, Lupiana, Guadalajara',
          RECEPTION_LOCATION: 'Monasterio de Lupiana, Guadalajara',
          DINNER_LOCATION: 'Monasterio de Lupiana, Guadalajara',
          PARTY_LOCATION: 'Monasterio de Lupiana, Guadalajara',
          TRANSPORT_BACK_LOCATION: 'Buses will leave guests at Puerta de Alcalá and Rivas Vaciamadrid',

          // Travel
          HOTELS: 'HOTELS',
          FLIGHTS: 'FLIGHTS',
          TRANSPORT: 'TRANSPORT',
          WHAT_TO_SEE: 'WHAT TO SEE',

          ' - 10% discount code: BODA240824':
            ' - 10% discount code: BODA240824',
          ' - 15% discount code: BODA15':
            ' - 10% discount code: BODA15',
          'Check out the map we made for you!':
            'Check out the map we made for you!',

          HOTELS_DESC:
            'There are plenty of hotels and Airbnbs around the city center, we recommend stying ' +
            'around Barrio de Salamanca. In this neighborhood you will find shops, restaurants and plenty of things to see. ' +
            'If you decide to stay in a hotel, you can use the below discount codes we got secially for our guests to get a 10% or 20% discount.',
          FLIGHTS_DESC: 'When booking your flights, please choose Adolfo Suárez Madrid-Barajas Airport (MAD) as your destination. This is the only airport serving Madrid, ensuring a straightforward and convenient arrival for all our guests.',
          TRANSPORT_DESC: 'From Adolfo Suárez Madrid-Barajas Airport (MAD) to the city center, you have several transport options:\n' +
              '• <strong>Taxi</strong>: Available at the airport terminals, the ride to the city center takes approximately 20-30 minutes.\n' +
              '• <strong>Airport Express Bus</strong>: A direct bus service operating 24/7 that takes you to Atocha Station in about 40 minutes.\n' +
              '• <strong>Metro</strong>: Line 8 of the metro connects the airport to the center of Madrid quickly and economically.\n' +
              'To get around the city, the metro is the best option. Madrid has an extensive metro network that will efficiently take you to all corners of the city.',
          WHAT_TO_SEE_DESC:
            'Madrid is full of incredible sights you won\'t want to miss:\n' +
              '• <strong>Prado Museum</strong>: Home to a vast collection of European art, including masterpieces by Velázquez, Goya, and Bosch.\n' +
              '• <strong>Royal Palace</strong>: An impressive official residence offering tours of its opulent rooms and gardens.\n' +
              '• <strong>Retiro Park</strong>: A beautiful park where you can row a boat on the lake or explore its sculptures and monuments.\n' +
              '• <strong>Puerta del Sol</strong>: The vibrant heart of Madrid, famous for its New Year\'s Eve clock and the Bear and the Strawberry Tree statue.\n' +
              '• <strong>Gran Vía</strong>: An iconic street lined with shops, restaurants, and theaters, perfect for an afternoon of shopping and entertainment.',
          OPEN_THE_MAP: 'Open the map',

          // FAQ
          WHAT_IS_THE_DRESS_CODE: 'WHAT IS THE DRESS CODE?',
          WHAT_IF_I_DONT_RSVP: 'WHAT IF I DON’T RSVP?',
          CAN_I_BRING_A_PLUS_ONE: 'CAN I BRING A PLUS ONE?',
          WHEN_SHOULD_I_ARRIVE: 'WHEN SHOULD I ARRIVE?',
          ARE_KIDS_INVITED: 'ARE KIDS INVITED?',
          WHERE_CAN_I_PARK: 'WHERE CAN I PARK?',
          WILL_TRANSPORT_BE_OFFERED: 'WILL TRANSPORT BE OFFERED?',
          HOW_CAN_WE_CONTRIBUTE_TO_YOUR_HONEYMOON_FUND:
            'HOW CAN WE CONTRIBUTE TO YOUR HONEYMOON FUND?',
          // FAQs: answers
          WHAT_IS_THE_DRESS_CODE_ANSWER:
              '<strong>Men</strong>: Morning suit or dark suit. Please, avoid tuxedos. \n' +
              '<strong>Women</strong> Long cocktail dress. Please, avoid light colors such as white, ivory, pales and champagne-hued shades. \n' +
              'You can see some inspiration from spanish weddings <a href=\"https://www.pinterest.es/son96ariza/dress-code/" target=\"_blank\" rel=\"noreferrer noopener\">here</a>.',
          WHAT_IF_I_DONT_RSVP_ANSWER:
            'To help us with planning, we kindly ask that you RSVP by the date provided on your formal invitation.\nIf you are having trouble with the RSVP, please contact Sonia or Andreas.',
          CAN_I_BRING_A_PLUS_ONE_ANSWER:
            'We kindly ask that you refrain from bringing a plus one unless it is specifically indicated on your invitation.' +
            'We appreciate your understanding.',
          WHEN_SHOULD_I_ARRIVE_ANSWER:
            'The ceremony will start at 17:00 PM. We recommend arriving 15-20 minutes before the start of the ceremony to make sure everyone is seated on time.',

          ARE_KIDS_INVITED_ANSWER:
            'Although we adore your little ones, we have decided to keep our wedding an adults-only event. ' +
            'We hope you understand and enjoy the night off!',
          HOW_CAN_WE_CONTRIBUTE_TO_YOUR_HONEYMOON_FUND_ANSWER:
              'The greatest gift of all is your presence on our special day! However should you wish to contribute to our honeymoon or give us a gift, please find here a <a href=\"https://onskeskyen.dk/s/dhv13t\" target=\"_blank\" rel=\"noreferrer noopener\">link</a> to the registry or our bank account below. As the wedding is in Madrid and traveling with gifts may be difficult, our address is Mikkel Bryggers Gade 3B, 1th, 1460 Copenhagen.\n' +
              '<strong>IBAN</strong>: LT52 3250 0216 3391 4662 \n' +
              '<strong>BIC/SWIFT</strong>: REVOLT21 \n \n' +
              '<strong>MobilePay BOX</strong>: 1191NK',
          WHERE_CAN_I_PARK_ANSWER: 'On Saturday 24 August, the village of Lupiana is celebrating its regional festivities, ' +
              'so we recommend that if you take your car with you, you park on the outskirts of the village and walk up to the church. ' +
              'The Monastery is a 10 minute drive from the village and has parking facilities. ',
          WILL_TRANSPORT_BE_OFFERED_ANSWER:
            'Yes, we will provide buses from the hotels in Madrid to the church and venue and back to the city after the party. ' +
            'If you wish to leave earlier than the planned buses, we will provide contact information for taxis near the venue. ',

          // RSVP
          RSVP_TEXT:
            'We are thrilled to extend this invitation to ' +
            'our special day and would be honored by your presence. ' +
              'Please kindly RSVP by July 15th to let us ' +
            "know if you'll be able to join us for our wedding celebration. " +
            'You can indicate your response by filling out this form. ',
          'Who is confirming?': 'Who is confirming?',
          'Please enter your name(s)...': 'Please enter your name(s)...',
          'Ready to celebrate with us?': 'Ready to celebrate with us?',
          'Fantastic!': 'Fantastic!',
          'Would you like to use the shuttle service?':
            'Would you like to use the bus service from the venue?',
          RSVP_YES: 'Yes',
          RSVP_NO: 'No',
          RSVP_INPUT_2: 'Any food restrictions?',
          RSVP_INPUT_3: 'Do you have any comment or question?',
          'One last thing...': 'One last thing...',
          "Are you sure? We'll miss you!": "Are you sure? We'll miss you!",
          RSVP_CONTACT_LABEL: 'Contact details',
          RSVP_PHONE_PLC: 'Phone number',
          RSVP_EMAIL_PLC: 'Email',
          BUTTON_BACK: 'Back',
          BUTTON_CONFIRM: 'Confirm',
          BUTTON_QUESTION: 'Do you want to confirm?',
          RSVP_THANK_YOU: 'Thank you!',
          TOAST_SUCCESS_RSVP: 'Thank you for your RSVP!',
          TOAST_ERROR_RSVP: 'Oops! You already RSVPs. No need to do it again',
          TOAST_ERROR_LOGIN: 'Oops! Wrong password.',

          // GUEST LIST
          GUEST_LIST_TITLE: 'Current Guest List',
          GL_GUEST_NAME: 'GUEST NAME',
          GL_EMAIL: 'EMAIL',
          GL_PHONE_NUMBER: 'PHONE NUMBER',
          GL_CONFIRMED: 'CONFIRMED',
          GL_SHUTTLE: 'SHUTTLE',
          GL_FOOD_RESTRICTION: 'FOOD RESTRICTION',
          GL_QUESTIONS: 'QUESTIONS/ COMMENTS',
          GL_YES: 'Yes',
          GL_NO: 'No',
          GL_DOWNLOAD_TEXT: 'Download the Guest List',
          GL_PAGE: 'Page',

          // FOOTER
          FOOTER_TEXT:
            'We look forward to celebrating with you. \n For any questions you can contact us at ',
          RIGHTS: 'All rights reserved.'
        },
      },
      es: {
        translations: {
          // Topbar
          HOME: 'BIENVENIDA',
          DRINKS: 'PRE BODA',
          TIMELINE: 'AGENDA',
          TRAVEL: 'VIAJE',
          RSVP: 'CONFIRMAR ASISTENCIA',
          FAQs: 'FAQs',
          GUEST_LIST: 'LISTA DE INVITADOS',

          // Login
          SEND: 'ENVIAR',
          'Please enter your invitation code...':
            'Por favor introduce tu codigo...',

          // Home
          SONIA_AND_ANDREAS: 'SONIA\n & \nANDREAS',
          WELCOME_PAGE_TITLE: 'Bienvenidos a nuestra boda',
          DATE: 'MADRID, ES \n•\n AGOSTO 24, 2024',
          RSVP_LINK: 'Confirmar asistencia',
          REGISTRY: 'LISTA DE BODAS',
          REGISTRY_LINK: 'Ver lista',
          WELCOME_PAGE_FIRST_PARAGRAPH:
            'Estamos deseando celebrar nuestro dia con todos nuestros seres queridos. Vuestra presencia es el mejor regalo de todos.',
          WELCOME_PAGE_SECOND_PARAGRAPH:
            'Para aquellos que quieran contribuir a nuestra aventura, hemos creado un registro. ¡Les agradecemos mucho su generosidad!',
          WELCOME_PAGE_TEXT:
            '¡Estamos muy emocionados de que hayáis encontrado nuestra página web! ' +
            'Nos morimos de ganas de compartir nuestra historia y todos los detalles sobre nuestra ' +
            'celebración contigo.',
          HOME_REGISTRY_TEXT:
            ' Vuestra presencia es nuestro mejor regalo, pero si queréis contribuir en nuestra futura vida juntos o luna de miel, os dejamos aquí nuestra lista de bodas y cuenta. Muchas gracias!',
          BANK_ACCOUNT: '<strong>IBAN</strong>: LT52 3250 0216 3391 4662\n' +
              '<strong>BIC/SWIFT</strong>: REVOLT21 \n\n' +
              '<strong>MobilePay BOX</strong>: 1191NK \n',
          HOME_RSVP_TEXT:
            '¡Reservad vuestra agenda! Por favor, confirma tu asistencia antes del 15 de Julio usando el formulario ' +
            'a continuación. ' +
            'Estamos deseando compartir este día especial con vosotros.',

          // Timeline
          THE_DAY: 'El gran dia',
          THE_DAY_DATE: 'Agosto 24, 2024',
          PREVIOUS_DAY: 'Brindemos juntos',
          PREVIOUS_DAY_DATE: 'Viernes 23 de Agosto, 2024',
          WELCOME_DRINKS: 'PRE BODA',
          DRESS_CODE: 'CÓDIGO DE VESTIMENTA',
          DRESS_CODE_DRINKS: 'Semiformal',
          DRESS_CODE_WEDDING_WOMAN: 'Mujeres: Vestido largo de cóctel',
          DRESS_CODE_WEDDING_MAN: 'Hombres: Chaqué o traje oscuro',
          BOARD_INSPO: 'Podéis ver algunos ejemplos <a href=\"https://www.pinterest.es/son96ariza/dress-code/" target=\"_blank\" rel=\"noreferrer noopener\">aquí</a>. Más información en \"FAQs\"',
          RESERVE_THE_DATE: 'Reserva la fecha',
          TRANSPORT_CEREMONY: 'A LA CEREMONIA',
          PRE_WEDDING: 'PRE BODA',
          CEREMONY: 'CEREMONIA',
          RECEPTION: 'RECEPCIÓN & CÓCTEL',
          PRE_WEDDING_DATE: 'Agosto 23, 2024',
          WEDDING_DATE: 'Agosto 24, 2024',
          ADD_TO_CALENDAR: 'Añadir al calendario',
          VIEW_ON_MAPS: 'Ver en el mapa',
          DINNER: 'CENA',
          PARTY: 'FIESTA',
          TRANSPORT_BACK: 'HASTA PRONTO',

          WELCOME_DRINKS_LOCATION: 'Gran Café El Espejo',
          TRANSPORT_CEREMONY_LOCATION: 'Autobuses saldrán del Hotel H10 Puerta de Alcalá.',
          TRANSPORT_CEREMONY_LOCATION_2: 'Autobuses pasarán por el polideportivo de Rivas-Vaciamadrid.',
          CEREMONY_LOCATION: 'Iglesia de San Pedro Apóstol, Lupiana, Guadalajara',
          RECEPTION_LOCATION: 'Monasterio de Lupiana, Guadalajara',
          DINNER_LOCATION: 'Monasterio de Lupiana, Guadalajara',
          PARTY_LOCATION: 'Monasterio de Lupiana, Guadalajara',
          TRANSPORT_BACK_LOCATION: 'Autobuses vuelven a Puerta de Alcalá y Rivas Vaciamadrid',

          // Travel
          HOTELS: 'HOTELES',
          FLIGHTS: 'VUELOS',
          TRANSPORT: 'TRANSPORTE',
          WHAT_TO_SEE: 'QUÉ VER',

          ' - 10% discount code: BODA240824':
            ' - 10% codigo descuento: BODA240824',
          ' - 15% discount code:  BODA15':
            ' - 15% codigo descuento: BODA15',
          'Check out the map we made for you!':
            '¡Mira el mapa que hemos hecho para ti!',

          HOTELS_DESC:
            'Hay muchos hoteles y Airbnbs alrededor del centro de la ciudad, recomendamos quedarse ' +
            'en el Barrio de Salamanca. En este barrio encontrarás tiendas, restaurantes y muchas cosas que ver. ' +
            'Si decides hospedarte en un hotel, puedes utilizar los códigos de descuento que hemos conseguido especialmente para vosotros.',
          FLIGHTS_DESC: 'Al reservar tus vuelos, elige como destino el Aeropuerto Adolfo Suárez Madrid-Barajas (MAD). Este es el único aeropuerto en Madrid, lo que garantiza una llegada sencilla.',
          TRANSPORT_DESC: 'Desde el Aeropuerto Adolfo Suárez Madrid-Barajas (MAD) hasta el centro de Madrid, tienes varias opciones de transporte:\n' +
              '• <strong>Taxi</strong>: Disponible en las terminales del aeropuerto, el trayecto al centro de la ciudad dura aproximadamente 20-30 minutos.\n' +
              '• <strong>Autobús Exprés</strong>: Un servicio de autobús directo que opera las 24 horas y te lleva a la estación de Atocha en unos 40 minutos.\n' +
              '• <strong>Metro</strong>: La línea 8 del metro conecta el aeropuerto con el centro de Madrid de forma rápida y económica.\n' +
              'Para moverte por la ciudad, esta es la mejor opción. Madrid cuenta con una extensa red de líneas de metro que te llevarán a todos los rincones de la ciudad de manera eficiente.',
          WHAT_TO_SEE_DESC:
            'Madrid está llena de lugares increíbles que no te puedes perder:\n' +
              '• <strong>Museo del Prado</strong>: Hogar de una vasta colección de arte europeo, incluyendo obras maestras de Velázquez, Goya y El Bosco.\n' +
              '• <strong>Palacio Real</strong>: Una impresionante residencia oficial que ofrece tours por sus opulentas habitaciones y jardines.\n' +
              '• <strong>Parque del Retiro</strong>: Un hermoso parque donde puedes pasear en barca por el lago o explorar sus esculturas y monumentos.\n' +
              '• <strong>Puerta del Sol</strong>: El corazón vibrante de Madrid, famoso por su reloj y la estatua del Oso y el Madroño.\n' +
              '• <strong>Gran Vía</strong>: Una calle icónica llena de tiendas, restaurantes y teatros, perfecta para una tarde de compras y entretenimiento.',
          OPEN_THE_MAP: 'Abre el mapa',

          // FAQ
          WHAT_IS_THE_DRESS_CODE: '¿CUÁL ES EL CÓDIGO DE VESTIMENTA?',
          WHAT_IF_I_DONT_RSVP: '¿QUÉ PASA SI NO CONFIRMO ASISTENCIA?',
          CAN_I_BRING_A_PLUS_ONE: '¿PUEDO TRAER UN ACOMPAÑANTE?',
          WHEN_SHOULD_I_ARRIVE: '¿CUÁNDO DEBO LLEGAR?',
          ARE_KIDS_INVITED: '¿ESTÁN INVITADOS LOS NIÑOS?',
          WHERE_CAN_I_PARK: '¿DONDE PUEDO APARCAR CERCA DE LA IGLESIA?',
          WILL_TRANSPORT_BE_OFFERED: '¿HABRA SERVICIO DE TRANSPORTE?',
          HOW_CAN_WE_CONTRIBUTE_TO_YOUR_HONEYMOON_FUND:
            '¿CÓMO PODEMOS CONTRIBUIR A VUESTRA LUNA DE MIEL?',

          // FAQs: answers
          WHAT_IS_THE_DRESS_CODE_ANSWER:
              '<strong>Hombres</strong>: Chaqué o traje oscuro. \n' +
              '<strong>Mujeres</strong>: Vestido de cóctel largo. Por favor, evitar colores claros como blanco, marfil, tonos pálidos y champán.  \n' +
              'Podéis ver algunos ejemplos <a href=\"https://www.pinterest.es/son96ariza/dress-code/" target=\"_blank\" rel=\"noreferrer noopener\">aquí</a>.',
          WHAT_IF_I_DONT_RSVP_ANSWER:
            'Para ayudarnos con la planificación, le pedimos amablemente que confirme su asistencia para la fecha indicada en su invitación formal.\nSi tiene dificultades para confirmar, comuníquese con Sonia o Andreas.',
          CAN_I_BRING_A_PLUS_ONE_ANSWER:
            'Les pedimos amablemente que se abstengan de traer acompañante a menos que esté específicamente indicado en su invitación. ' +
            'Agradecemos su comprensión.',

          WHEN_SHOULD_I_ARRIVE_ANSWER:
            'La ceremonia comenzará a las 17:00 PM. Recomendamos llegar 15-20 minutos antes de la ceremonia para asegurarnos que todos los invitados estén sentados a tiempo. ',

          ARE_KIDS_INVITED_ANSWER:
            'Aunque adoramos a los más pequeños, hemos decidido mantener nuestra boda como un evento solo para adultos. ' +
            '¡Esperamos que lo entienda y disfrute de la noche libre!',
          HOW_CAN_WE_CONTRIBUTE_TO_YOUR_HONEYMOON_FUND_ANSWER:
            'Vuestra presencia es nuestro mejor regalo, pero si queréis contribuir en nuestra futura vida juntos o luna de miel, os dejamos aquí el <a href=\"https://onskeskyen.dk/s/dhv13t\" target=\"_blank\" rel=\"noreferrer noopener\">link</a> nuestra lista de bodas y cuenta. Para evitar viajar con los regalos, nuestra dirección es Mikkel Bryggers Gade 3B, 1th, 1460 Copenhagen. Muchas gracias ! \n' +
             '<strong>IBAN</strong>: LT52 3250 0216 3391 4662 \n' +
            '<strong>BIC/SWIFT</strong>: REVOLT21 \n \n' +
            '<strong>MobilePay BOX</strong>: 1191NK \n',

          WHERE_CAN_I_PARK_ANSWER: 'El sábado 24 de agosto, son las fiestas del pueblo de Lupiana, ' +
              'por lo que recomendamos que si lleváis vuestro coche aparquéis a las afueras del pueblo y subáis andando a la iglesia. ' +
              'El Monasterio esta a 10 minutos en coche del pueblo y tiene aparcamiento.',
          WILL_TRANSPORT_BE_OFFERED_ANSWER:
            'Sí, proporcionaremos autobuses desde los hoteles en Madrid hasta la iglesia y el lugar del evento, y de regreso a la ciudad después de la fiesta. ' +
            'Si desean salir antes de la hora prevista de los autobuses, proporcionaremos información de contacto para taxis cerca del lugar del evento.',

          // RSVP
          RSVP_TEXT:
            'Estamos encantados de contar con vosotros en nuestro día especial. ' +
              'Por favor, confirma tu asistencia antes del 15 de Julio para hacernos saber si ' +
            'podrás acompañarnos en nuestra celebración. Puedes hacerlo a través de este formulario.',
          'Who is confirming?': '¿Quién está confirmando?',
          'Please enter your name(s)...':
            'Por favor, introduce tu(s) nombre(s)...',
          'Ready to celebrate with us?': '¿Listo para celebrar con nosotros?',
          'Fantastic!': '¡Fantástico!',
          'Would you like to use the shuttle service?':
            '¿Te gustaría utilizar el servicio de transporte de vuelta?',
          RSVP_YES: 'Sí',
          RSVP_NO: 'No',
          RSVP_INPUT_2: '¿Tienes alguna restricción alimenticia?',
          RSVP_INPUT_3: '¿Tienes algún comentario o pregunta?',
          'One last thing...': 'Una última cosa...',
          "Are you sure? We'll miss you!": '¿Estás seguro? ¡Te echaremos de menos!',
          RSVP_CONTACT_LABEL: 'Detalles de contacto',
          RSVP_PHONE_PLC: 'Número de teléfono',
          RSVP_EMAIL_PLC: 'Correo electrónico',
          BUTTON_BACK: 'Volver',
          BUTTON_CONFIRM: 'Confirmar',
          BUTTON_QUESTION: '¿Quieres confirmar?',
          RSVP_THANK_YOU: '¡Gracias!',
          TOAST_SUCCESS_RSVP: '¡Gracias por tu confirmación!',
          TOAST_ERROR_LOGIN: 'Oops! Contraseña incorrecta.',
          TOAST_ERROR_RSVP:
            '¡Oops! Ya has confirmado tu asistencia. No es necesario hacerlo de nuevo.',

          // GUEST LIST
          GUEST_LIST_TITLE: 'Lista de Invitados',
          GL_GUEST_NAME: 'NOMBRE',
          GL_EMAIL: 'CORREO',
          GL_PHONE_NUMBER: 'TELEFONO',
          GL_CONFIRMED: 'CONFIRMADO',
          GL_SHUTTLE: 'TRANSPORTE',
          GL_FOOD_RESTRICTION: 'RESTRICTIÒN ALIMENTARIA',
          GL_QUESTIONS: 'PREGUNTAS/ COMENTARIOS',
          GL_YES: 'Sì',
          GL_NO: 'No',
          GL_DOWNLOAD_TEXT: 'Descargar la Lista de Invitados',
          GL_PAGE: 'Pagina',

          // FOOTER
          FOOTER_TEXT:
           'Estamos deseando celebrarlo con todos vosotros. \n Cualquier duda escribir a ',
          RIGHTS: ''
        },
      },
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

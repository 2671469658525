import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Route, Routes as ReactRouterDOMRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'components/auth/Auth';

import { Authenticated } from "components/auth/Authenticated";
import GuestList from "components/pages/guestlist/GuestList";
import Timeline from "components/pages/timeline/Timeline";
import Login from "components/pages/login/Login";
import Travel from "components/pages/travel/Travel";
import Home from "components/pages/home/Home";
import RSVP from "components/pages/rsvp/RSVP";
import FAQ from "components/pages/faq/FAQ";
import Drinks from 'components/pages/drinks/Drinks';

const Routes = () => {
  const [auth] = useAuth();
  const [isValidToken, setIsValidToken] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [user, setUser] = useState();
  // Translate
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(async () => {
    const language = localStorage.getItem('i18nextLng');
    await i18n.changeLanguage(language);
    setCurrentLanguage(language);
  }, [i18n]);

  useEffect(() => {
    const token = auth?.token;
    const userAuth = auth?.user;

    if (token) {
      setIsValidToken(true);
      setUser(userAuth);
    } else {
      setIsValidToken(false);
    }
  }, [isValidToken]);

  useEffect(() => {
    // store in local storage the language
    changeLanguage();
  }, [currentLanguage, changeLanguage]);

  return (
    <ReactRouterDOMRoutes>
      <Route
        element={
          <Authenticated
            user={user}
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
          />
        }
      >
        <Route path="/" element={<Home />} exact />
        <Route path="/welcome-drinks" element={<Drinks />} exact />
        <Route path="/timeline" element={<Timeline />} exact />
        <Route path="/FAQs" element={<FAQ />} exact />
        <Route path="/travel" element={<Travel />} exact />
        <Route path="/rsvp" element={<RSVP />} exact />
        <Route path="/guestList" element={<GuestList />} exact />
      </Route>
      <Route
        path="/login"
        element={
          <Login
            setIsValidToken={setIsValidToken}
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
          />
        }
        exact
      />
    </ReactRouterDOMRoutes>
  );
};

export default Routes;

import styling from './Header.module.scss';
import { Trans } from 'react-i18next';
import React from 'react';

const Header = ({ title, subtitle, extraContent, img }) => {
  return (
      <div
          className={styling.main}
          style={{
              backgroundImage: `url(${img})`,
              objectFit: 'cover',
          }}
      >
          <div className={styling.header}>
              <p className={styling.title}>
                  <Trans>{title}</Trans>
              </p>

              {subtitle && <p className={styling.subtitle}>{subtitle}</p>}

              {extraContent && extraContent}
          </div>
      </div>
  );
};

export default Header;

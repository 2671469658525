import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconDownload } from '@tabler/icons-react';
import axios from 'axios';
import * as XLSX from 'xlsx'; // Import xlsx library

import Content from 'components/layout/content/Content';
import Header from 'components/UI/header/Header';
import Headline from 'components/UI/headline/Headline';

import HeaderImg from 'media/images/guest-list.webp';

import styling from './GuestList.module.scss';

const GuestList = () => {
  const itemsPerPage = 10; // Adjust
  const [currentPage, setCurrentPage] = useState(1);
  const [guestList, setGuestList] = useState();
  const { t } = useTranslation();

  const guestTitle = [
    t('GL_GUEST_NAME'),
    t('GL_EMAIL'),
    t('GL_PHONE_NUMBER'),
    t('GL_CONFIRMED'),
    t('GL_SHUTTLE'),
    t('GL_FOOD_RESTRICTION'),
    t('GL_QUESTIONS'),
  ];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentGuestList = guestList?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(guestList?.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchGuestList = async () => {
    const { data } = await axios.get('/getGuestList');
    if (data?.guestList?.length > 0) {
      setGuestList(data?.guestList);
    }
  };

  useEffect(() => {
    fetchGuestList();
  }, []);

  const handleGuestListDownload = () => {
    const modifiedGuestList = guestList?.map((guest) => ({
      [t('GL_GUEST_NAME')]: guest.name,
      [t('GL_EMAIL')]: guest.email,
      [t('GL_PHONE_NUMBER')]: guest.phone,
      [t('GL_CONFIRMED')]: guest.isConfirmed ? t('GL_YES') : t('GL_NO'),
      [t('GL_SHUTTLE')]: guest.isShuttle ? t('GL_YES') : t('GL_NO'),
      [t('GL_FOOD_RESTRICTION')]:
        guest.foodRestrictions !== '' ? guest.foodRestrictions : '/',
      [t('GL_QUESTIONS')]: guest.comments !== '' ? guest.comments : '/',
    }));
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add a worksheet with the modified guest list data
    const ws = XLSX.utils.json_to_sheet(modifiedGuestList);
    XLSX.utils.book_append_sheet(wb, ws, 'Guest List');
    // Save the workbook to an Excel file
    XLSX.writeFile(wb, 'GuestList.xlsx');
  };

  return (
    <Content>
      <Header
        title="GUEST_LIST"
        img={HeaderImg}
      />

      <Headline level={1} classStyle={styling.title}>
        <Trans>{'GUEST_LIST_TITLE'}</Trans>
      </Headline>

      <div className={styling.container}>
        <table className={styling.table}>
          <thead>
            <tr>
              {guestTitle?.map((name, index) => (
                <th key={index}>{name}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {currentGuestList?.map((guest, index) => (
              <tr key={index}>
                <td>{guest.name}</td>
                <td>{guest.email}</td>
                <td>{guest.phone}</td>
                <td>{guest.isConfirmed ? t('GL_YES') : t('GL_NO')}</td>
                <td>{guest?.isShuttle ? t('GL_YES') : t('GL_NO')}</td>
                <td>{guest.foodRestrictions}</td>
                <td>{guest.comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styling.pages}>
        <div className={styling.pagination}>
          {/* Pagination */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={
                index + 1 === currentPage
                  ? styling.activePage
                  : styling.inactivePage
              }
              onClick={() => paginate(index + 1)}
            >
              <Trans>{'GL_PAGE'}</Trans> {index + 1}
            </button>
          ))}
        </div>

        <div className={styling.downloadDiv}>
          <button
            className={styling.downloadButton}
            onClick={handleGuestListDownload}
          >
            <Trans>{'GL_DOWNLOAD_TEXT'}</Trans>
            <IconDownload/>
          </button>
        </div>
      </div>
    </Content>
  );
};

export default GuestList;

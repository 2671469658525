import React from 'react';
import { Trans } from 'react-i18next';

import Headline from 'components/UI/headline/Headline';

import styling from './Section.module.scss';
import { IconMapPin } from '@tabler/icons-react';

const Section = ({ title, time, location, address, secondLocation, secondTime, secondAddress }) => {
    const language = localStorage?.getItem('i18nextLng');

    const redirectToGoogleMaps = (address) => {
        // Use navigate to redirect to the Google Maps URL
        window.open(`${address}`, '_blank', 'rel=noopener noreferrer');
    };

    return (
        <div className={styling.section}>
            <Headline level={2} classStyle={styling.title}>
                <Trans>{title}</Trans>
            </Headline>

            <div className={styling.info}>
                <p>{time}</p>

                <div className={styling.location} onClick={() => redirectToGoogleMaps(address)}>
                    <p className={address ? styling.locationMap : styling.locationText}><IconMapPin size={"1rem"}
                                                                                                    stroke={2}/><Trans>{location}</Trans>
                    </p>
                </div>

                <p hidden={language === 'en' || !secondAddress} style={{marginTop: "1.5rem"}}>{secondTime}</p>

                <div hidden={language === 'en' || !secondAddress} className={styling.location} onClick={() => redirectToGoogleMaps(secondAddress)}>
                    <p className={address ? styling.locationMap : styling.locationText}><IconMapPin size={"1rem"}
                                                                                                    stroke={2}/><Trans>{secondLocation}</Trans>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Section;

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Modal, Button } from 'antd';

import Header from 'components/UI/header/Header';
import Paragraph from 'components/UI/paragraph/Paragraph';
import Content from 'components/layout/content/Content';
import Input from 'components/UI/input/Input';
import Toggle from 'components/UI/toggle/Toggle';
import Card from 'components/UI/card/Card';
import Divider from 'components/UI/divider/Divider';

import HeaderImg from 'media/images/rsvp.webp';

import styling from './RSVP.module.scss';

const initState = {
  names: '',
  phone: '',
  email: '',
  foodRestrictions: '',
  comments: '',
  isConfirmed: true,
  isShuttleSelected: true,
};

const RSVP = () => {
  const [visible, setVisible] = useState(false);
  const [hasRSVP, setHasRSVP] = useState(false);
  const [
    {
      names,
      phone,
      email,
      foodRestrictions,
      comments,
      isConfirmed,
      isShuttleSelected,
    },
    setState,
  ] = useState(initState);
  const { t } = useTranslation();

  setTimeout(() => {
    setHasRSVP(false);
  }, 3000);

  const toggleConfirm = () => {
    setState((prevState) => ({
      ...prevState,
      isConfirmed: !prevState.isConfirmed,
    }));
  };

  const toggleShuttle = () => {
    setState((prevState) => ({
      ...prevState,
      isShuttleSelected: !prevState.isShuttleSelected,
    }));
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const openPopUp = (e) => {
    e.preventDefault();
    setVisible(true);
  };

  const sendEmail = async (newGuestData) => {
    try {
      await axios.post('/sendEmail', newGuestData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRSVP = async () => {
    try {
      const newGuestData = {
        name: names,
        phone: phone,
        email: email,
        isConfirmed: isConfirmed,
        isShuttle: isShuttleSelected,
        foodRestrictions: foodRestrictions,
        comments: comments,
      };
      const { data } = await axios.post('/createGuest', newGuestData);

      if (data?.message === 'success') {
        toast.success(t('TOAST_SUCCESS_RSVP'));
        localStorage.setItem('rsvp', true);
        // Send email
        sendEmail(newGuestData);
        // Set hasRSVP
        setHasRSVP(true);
      } else if (data?.message === 'Email or name already exists') {
        toast.error(t('TOAST_ERROR_RSVP'));
      }
      setVisible(false);
    } catch (error) {}
  };

  const handleOk = () => {
    handleRSVP();
  };

  const onCancel = () => {
    setVisible(false);
  };

  const inputFields = [
    {
      name: 'foodRestrictions',
      label: t('RSVP_INPUT_2'),
      value: foodRestrictions,
      required: false,
    },
    {
      name: 'comments',
      label: t('RSVP_INPUT_3'),
      value: comments,
      required: false,
      isTextArea: true,
    },
  ];

  const personalInfo = [
    {
      name: 'phone',
      label: t('RSVP_CONTACT_LABEL'),
      placeholder: t('RSVP_PHONE_PLC'),
      value: phone,
      required: true,
    },
    {
      name: 'email',
      label: '',
      placeholder: t('RSVP_EMAIL_PLC'),
      value: email,
      required: true,
    },
  ];

  const lastStepLabel = isConfirmed
    ? t('One last thing...')
    : t("Are you sure? We'll miss you!");

  return (
    <Content>
      <Header
        title={'RSVP'}
        img={HeaderImg}
      />

      <Modal
        className={styling.modal}
        open={visible}
        title=""
        onOk={handleOk}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            <Trans>{'BUTTON_BACK'}</Trans>
          </Button>,
          <Button key="submit" onClick={handleOk}>
            <Trans>{'BUTTON_CONFIRM'}</Trans>
          </Button>,
        ]}
      >
        <p>
          <Trans>{'BUTTON_QUESTION'}</Trans>
        </p>
      </Modal>

      <div className={styling.container}>
        <Card showBorder>
          <div className={styling.header}>
            <h3 className={styling.title}><Trans>{'SONIA_AND_ANDREAS'}</Trans></h3>

            <h6 className={styling.subtitle}>
              <Trans>{'WEDDING_DATE'}</Trans>
            </h6>

            <Divider />

            <Paragraph level={1} classStyle={styling.caption}>
              <Trans>{'RSVP_TEXT'}</Trans>
            </Paragraph>
          </div>
          {hasRSVP ? (
            <>
              <p className={styling.thankYou}>
                <Trans>{'RSVP_THANK_YOU'}</Trans>
              </p>
            </>
          ) : (
            <form className={styling.form} onSubmit={openPopUp}>
              <div className={styling.section}>
                <Input
                  name="names"
                  label={t('Who is confirming?')}
                  placeholder={t('Please enter your name(s)...')}
                  value={names}
                  onChange={handleInputChange}
                  required
                />

                <Toggle
                  label={t('Ready to celebrate with us?')}
                  checked={isConfirmed}
                  onClick={toggleConfirm}
                  position={'vertical'}
                  required
                />
              </div>

              <div hidden={!isConfirmed}>
                <h3 className={styling.sectionTitle}>
                  <Trans>Fantastic!</Trans>
                </h3>

                <div className={styling.section}>
                  <Toggle
                    label={t('Would you like to use the shuttle service?')}
                    checked={isShuttleSelected}
                    onClick={toggleShuttle}
                  />

                  {inputFields.map((field, index) => (
                    <Input
                      key={index}
                      name={field.name}
                      label={field.label}
                      value={field.value}
                      onChange={handleInputChange}
                      required={field.required}
                      isTextArea={field.isTextArea}
                    />
                  ))}
                </div>
              </div>

              <div>
                <h3 className={styling.sectionTitle}>{lastStepLabel}</h3>

                <div className={styling.contact}>
                  {personalInfo.map((field, index) => (
                    <Input
                      key={index}
                      name={field.name}
                      label={field.label}
                      value={field.value}
                      placeholder={field.placeholder}
                      onChange={handleInputChange}
                      required={field.required}
                      isTextArea={field.isTextArea}
                    />
                  ))}
                </div>
              </div>

              <button className={styling.confirmation} type="submit">
                <Trans>RSVP</Trans>
              </button>
            </form>
          )}
        </Card>
      </div>
    </Content>
  );
};

export default RSVP;

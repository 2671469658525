import React from "react";
import ReactDOM from "react-dom/client";
import App from "components/app/App.js";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "components/auth/Auth";
import "stylesheets/global.scss";
import "./translations/translations";

const app = (
  <AuthProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(app);

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

import Content from 'components/layout/content/Content';
import Headline from 'components/UI/headline/Headline';
import Header from 'components/UI/header/Header';
import Paragraph from 'components/UI/paragraph/Paragraph';

import HomeImg from 'media/images/home-2.webp';
import RsvpImg from 'media/images/home-rsvp.webp';
import RegistryImg from 'media/images/home-registry.webp';

import CountdownTimer from './countdown/CountDown';

import styling from './Home.module.scss';
import { IconArrowRight } from '@tabler/icons-react';

const Home = () => {
    const auth = localStorage?.getItem('auth');
    const language = localStorage?.getItem('i18nextLng');
    const authJson = JSON.parse(auth);
    const user = authJson.user;

  const subtitle = <Trans>{'DATE'}</Trans>;

  return (
      <Content>
        <Header
          title="SONIA_AND_ANDREAS"
          subtitle={subtitle}
          extraContent={<CountdownTimer />}
          img={HomeImg}
      />

      <Headline level={1} classStyle={styling.mainTitle}>
        <Trans>{'WELCOME_PAGE_TITLE'}</Trans>
      </Headline>

      <Paragraph level={1} classStyle={styling.text}>
        <Trans>{'WELCOME_PAGE_TEXT'}</Trans>
      </Paragraph>

      <div className={styling.columns}>
          <div className={styling.columnLeft}>
              <div
                  className={styling.image}
                  style={{
                      backgroundImage: `url(${RegistryImg})`,
                      objectFit: 'cover',
                  }}
              />

              <div className={styling.sectionLeft}>
                  <Headline level={2} classStyle={styling.titleLeft}>
                      <Trans>{'RSVP'}</Trans>
                  </Headline>

                  <Paragraph level={2}>
                      <Trans>{'HOME_RSVP_TEXT'}</Trans>
                  </Paragraph>

                  <NavLink className={styling.link} to={'/rsvp'}>
                      <Trans>{'RSVP_LINK'}</Trans>

                      <IconArrowRight size={20} stroke={1.5}/>
                  </NavLink>
              </div>
          </div>

          <div className={styling.columnRight}>
              <div className={styling.sectionRight}>
                  <Headline level={2} classStyle={styling.titleRight}>
                      <Trans>{'REGISTRY'}</Trans>
                  </Headline>

                  <Paragraph level={2}>
                      <Trans>{'HOME_REGISTRY_TEXT'}</Trans>
                  </Paragraph>

                  <div className={styling.bank}>
                    <Paragraph level={1}><Trans>{'BANK_ACCOUNT'}</Trans></Paragraph>
                  </div>

                  <a
                      className={styling.link}
                      href="https://onskeskyen.dk/s/dhv13t"
                      target="_blank"
                      rel="noreferrer noopener"
                  >
                    <Trans>{'REGISTRY_LINK'}</Trans> <IconArrowRight size={20} stroke={1.5} />
                  </a>
              </div>

              <div
                  className={styling.imageRight}
                  style={{
                      backgroundImage: `url(${RsvpImg})`,
                      objectFit: 'cover',
                  }}
              />
          </div>
      </div>
      </Content>
  );
};

export default Home;

import React from 'react';
import { Trans } from 'react-i18next';

import Content from 'components/layout/content/Content';
import Header from 'components/UI/header/Header';
import Headline from 'components/UI/headline/Headline';

import HeaderImg from 'media/images/welcome-drinks.webp';

import { preWeddingInfo } from './preWeddingInfo';

import styling from './Drinks.module.scss';
import { IconMapPin } from '@tabler/icons-react';

const Drinks = () => {
    const auth = localStorage?.getItem('auth');
    const authJson = JSON.parse(auth);

    const drinksInfo = preWeddingInfo.filter(info => info?.user.includes(authJson?.user))[0];

    const redirectToGoogleMaps = (address) => {
        // Use navigate to redirect to the Google Maps URL
        window.open(`${address}`, '_blank', 'rel=noopener noreferrer');
    };

    return (
        <Content>
            <Header
              title={'DRINKS'}
              img={HeaderImg}
            />

            <Headline level={1} classStyle={styling.title}>
              <Trans>{'PREVIOUS_DAY'}</Trans>
            </Headline>

            <p className={styling.date}><Trans>{'PREVIOUS_DAY_DATE'}</Trans></p>
            <p className={styling.date}>{drinksInfo.time}</p>

            <div className={styling.location} onClick={() => redirectToGoogleMaps(drinksInfo.address)}>
                <p className={drinksInfo.address ? styling.locationMap : styling.locationText}>
                  <IconMapPin size={'1.2rem'} stroke={2}/>
                  <Trans>{drinksInfo.location}</Trans>
                </p>
            </div>

            <div className={styling.dressCode}>
               <p className={styling.dressCodeTitle}><Trans>{'DRESS_CODE'}</Trans></p>

                <p><Trans>{'DRESS_CODE_DRINKS'}</Trans></p>
            </div>
      </Content>
  );
};

export default Drinks;

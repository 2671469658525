import React from 'react';

import styling from './Card.module.scss';

const Card = ({children, hidden}) => {
    let style = styling.container;

    return (
        <div
            className={style}
             hidden={hidden}
        >
            {children}
        </div>
    )
}

export default Card;
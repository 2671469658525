import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Content from 'components/layout/content/Content';
import Header from 'components/UI/header/Header';
import Divider from 'components/UI/divider/Divider';
import Headline from 'components/UI/headline/Headline';

import HeaderImg from 'media/images/timeline-bw.webp';

import Section from './section/Section';
import { WeddingInfo } from './weddingInfo';

import styling from './Timeline.module.scss';

const Drinks = () => {
    const { t } = useTranslation();

    const auth = localStorage?.getItem('auth');
    const authJson = JSON.parse(auth);

    const timelineInfo = WeddingInfo.filter(info => info?.user.includes(authJson?.user));
    const eventRefs = useRef([]);

    return (
        <Content>
            <Header
                title={'TIMELINE'}
                img={HeaderImg}
            />
            <Headline level={1} classStyle={styling.title}>
                <Trans>{'THE_DAY'}</Trans>
            </Headline>

            <p className={styling.date}><Trans>{'THE_DAY_DATE'}</Trans></p>

            <div className={styling.dressCode}>
                <p className={styling.dressCodeTitle}><Trans>{'DRESS_CODE'}</Trans></p>
                <p><Trans>{'DRESS_CODE_WEDDING_MAN'}</Trans></p>
                <p><Trans>{'DRESS_CODE_WEDDING_WOMAN'}</Trans></p>
                <p className={styling.link} dangerouslySetInnerHTML={{ __html: t('BOARD_INSPO') }}></p>
            </div>

            <Divider/>

            <div className={styling.timelineContainer}>
                <svg className={styling.timelineSvg} viewBox="0 0 100 2000">
                    <path className={styling.timelinePath}
                          d="M50,0 C50,250 0,250 0,500 C0,750 50,750 50,1000 C50,1250 0,1250 0,1500 C0,1750 50,1750 50,2000"/>
                </svg>

                <div id="timeline" className={styling.timeline}>
                    {timelineInfo.map((section, index) => (
                        <div
                            key={index}
                            className={`${styling.event} ${index % 2 === 0 ? styling.left : styling.right}`}
                        >
                            <div className={styling.content} ref={el => eventRefs.current[index] = el}>
                                <Section
                                    key={index}
                                    title={section.title}
                                    date={section.date}
                                    time={section.time}
                                    location={section.location}
                                    address={section?.googleMapsAddress}
                                    secondAddress={section?.secondGoogleMapsAddress}
                                    secondLocation={section?.secondLocation}
                                    secondTime={section?.secondTime}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Content>
    );
};

export default Drinks;

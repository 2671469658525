const Information = [
  {
    id: 'recommendations',
    section: 'HOTELS',
    title: 'OUR RECOMENDATIONS',
    text: 'HOTELS_DESC',
    extraText: [
      {
        title: 'One Shot Hotels',
        text: ' - 10% discount code: BODA240824',
      },
      // {
      //   title: 'Radison BLU Madrid Prado',
      //   text: ' - 20% discount code: BODA240824',
      // },
      // {
      //   title: 'NH Hotels',
      //   text: ' - 10% discount code: BODA240824',
      // },
      {
        title: 'H10 Hotel Puerta de Alcalà',
        text: ' - 15% discount code:  BODA15',
      },
    ],
    isBulletPoint: true,
  },
  {
    id: 'flights',
    section: 'FLIGHTS',
    title: '',
    text: 'FLIGHTS_DESC',
    extraText: [],
    isBulletPoint: false,
  },
  {
    id: 'transport',
    section: 'TRANSPORT',
    title: '',
    text: 'TRANSPORT_DESC',
    extraText: [],
    isBulletPoint: false,
  },
  {
    id: 'whattosee',
    section: 'WHAT_TO_SEE',
    title: '',
    text: 'WHAT_TO_SEE_DESC',
    extraText: [
      {
        title: 'Check out the map we made for you!',
        text: '',
      },
    ],
    isBulletPoint: false,
  },
];

export default Information;

import React from 'react';
import { Trans } from 'react-i18next';

import styling from './Footer.module.scss';

const Footer = () => {
    return (
        <div className={styling.container}>
            <p className={styling.sentence}>
                <Trans>{'FOOTER_TEXT'}</Trans>
                <a href="mailto:soniayandreas24@gmail.com" className={styling.email}>soniayandreas24@gmail.com</a>
            </p>

            <div className={styling.rights}>
                <p>© 2024 Laura Purcaro & Alba Romero. <Trans>{'RIGHTS'}</Trans></p>
            </div>
        </div>
    )
};

export default Footer;
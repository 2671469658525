import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from 'components/auth/Auth';

import Input from 'components/UI/input/Input';
import LanguageSwitcher from 'components/shared/languageSwitcher/LanguageSwitcher';

import HomeImg from 'media/images/home-2.webp';

import styling from './Login.module.scss';
import { IconSquareRoundedChevronRightFilled} from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { BeatLoader } from 'react-spinners';
import Divider from '../../UI/divider/Divider';

const Login = ({ setIsValidToken, currentLanguage, setCurrentLanguage }) => {
  // State
  const [inputCode, setInputCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [auth, setAuth] = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // If user already logged in, redirect to Home
  const validateToken = async (token) => {
    const { data } = await axios.post('/requireLogIn', {
      token: token,
    });

    if (data.isValid === true) {
      navigate('/');
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    const auth = localStorage?.getItem('auth');
    const authJson = JSON.parse(auth);

    const token = authJson?.token;
    const expirationDate = authJson?.expirationDate;

    if (token && expirationDate) {
      if (new Date(expirationDate) < new Date()) {
        localStorage?.removeItem('auth');
        setIsValidToken(false);
        navigate('/login');
      } else {
        validateToken(token);
      }
    }
  }, []);

  const handleInputChange = ({ target: { value } }) => {
    setInputCode(value);
  };

  /**
   * Handles submission of the token
   * @param e {React.FormEvent} submit event
   * @return {Promise<void>}
   */
  const handleTokenSubmit = async (e) => {
    try {
      e.preventDefault();

      setIsSubmitting(true);

      const { data } = await axios.post('/validateCode', {
        code: inputCode,
      });

      setIsSubmitting(false);

      if (!data.error) {
        // Set expiration date to one day from now

        // Save token and expiration date in local storage
        setAuth({
          ...auth,
          user: data?.user,
          token: data?.token,
          expirationDate: data?.expirationDate,
        });
        setIsValidToken(true);

        // navigate to Home
        navigate('/');
      }

      if (data.error) {
        toast.error(t('TOAST_ERROR_LOGIN'));
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  return (
    <>
      <div
        className={styling.overlay}
        style={{
          backgroundImage: `url(${HomeImg})`,
          objectFit: 'cover',
        }}
      >
        <div className={styling.container}>
          <div className={styling.languages}>
            <LanguageSwitcher
              currentLanguage={currentLanguage}
              setCurrentLanguage={setCurrentLanguage}
            />
          </div>

          <div className={styling.loginContent}>
            <h1 className={styling.title}>
              SONIA <span className={styling.titleAnd}>&</span> ANDREAS
            </h1>

            <p className={styling.subtitle}>
              <Trans>{'WEDDING_DATE'}</Trans>
            </p>

            <Divider/>

              <form className={styling.form} onSubmit={handleTokenSubmit}>
                {isSubmitting ? <BeatLoader color="var(--color-6-800" /> :
                    <>
                      <Input
                        placeholder={t('Please enter your invitation code...')}
                        value={inputCode}
                        onChange={handleInputChange}
                        required
                      />

                      <button className={styling.button} type={'submit'}>
                        <IconSquareRoundedChevronRightFilled color={'var(--color-6-800)'} size={'2.5rem'}/>
                      </button>
                    </>
                }
              </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

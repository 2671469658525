import React, { useEffect, useState } from "react";
import styling from "./CountDown.module.scss";

const CountdownTimer = () => {
  const [countdown, setCountdown] = useState(calculateCountdown());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function calculateCountdown() {
    const weddingDate = new Date("August 24, 2024 00:00:00 GMT+02:00");
    const now = new Date();
    const timeDifference = weddingDate - now;

    if (timeDifference <= 0) {
      return {days: 0, hours: 0, minutes: 0, seconds: 0}
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  return (
    <p className={styling.countdown}>
      {countdown.days} D • {countdown.hours} H • {countdown.minutes} M •{" "}
      <span className={styling.countdown}>{countdown.seconds} S</span>
    </p>
  );
};

export default CountdownTimer;

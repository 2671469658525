import React, { useState } from 'react';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import styling from './Input.module.scss';

const Input = ({ name, onChange, value, placeholder, type, label, isTextArea, required = false, hidden, disabled }) => {
    // State
    const [showPassword, setShowPassword] = useState(false);


    /**
     * Toggles the visibility of the password content.
     */
    const actionIconClickHandler = () => {
        setShowPassword(prev => !prev);
    };



    // Determine field type
    const isPassword = type === 'password';
    let inputType = type || 'text';

    if (isPassword && showPassword) {
        inputType = 'text';
    }


    // Determine input field styling
    let style = styling.input;

    if (isTextArea) {
        style += ' ' + styling.textArea;
    }


    // Determine props
    const inputProps = {
        name: name,
        className: style,
        onChange: onChange,
        value: value ?? '',
        placeholder: placeholder,
        autoComplete: 'off',
        type: inputType,
        required: required || false,
        readOnly: !onChange,
        disabled: disabled || false,
        'data-input-field-element': 'input'
    };


    // Determine input field
    const inputField = (
        <div className={styling.wrapper}>
            {isTextArea ? <textarea {...inputProps}/> : <input {...inputProps}/>}

            {(isPassword && !isTextArea) && (
                <div
                    role="button"
                    tabIndex={0}
                    className={styling.actionIcon}
                    onClick={actionIconClickHandler}
                >
                    {showPassword ? <IconEyeOff color={"var(--color-6-800)"} stroke={"1.5px"}/> : <IconEye color={"var(--color-6-800)"} stroke={"1.5px"}/>}
                </div>
            )}
        </div>
    );


    // Determine whether the input field is
    // rendered with label or not.
    if (!label) {
        return inputField;
    }


    return (
        <div className={styling.container} hidden={hidden}>
                <span className={styling.label}>
                    {required && <span className={styling.asterisk}>*</span>}

                    {label}
                </span>

                {inputField}
        </div>
    );
};

export default Input;
import React from 'react';

import styling from './Paragraph.module.scss';

const Paragraph = ({ children, level, classStyle }) => {
  return (
    <div className={`${classStyle && classStyle} ${styling.container}`}>
      <p className={`${styling[`level${level}`] || styling.level1}`}>
        {children}
      </p>
    </div>
  );
};

export default Paragraph;

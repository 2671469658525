import React from 'react';

import styling from './LanguageSwitcher.module.scss';

const LanguageSwitcher = ({currentLanguage, setCurrentLanguage}) => {
    const handleLanguageChange = (value) => {
        localStorage.setItem("i18nextLng", value);
        setCurrentLanguage(value);
    };

    return (
        <div className={styling.languages}>
            <span
                className={styling.text}
                style={{ fontWeight: currentLanguage === "es" ? "bold" : "" }}
                onClick={() => handleLanguageChange("es")}
            >
              ES
            </span>
            <span className={styling.languages}> | </span>
            <span
                className={styling.text}
                style={{ fontWeight: currentLanguage === "en" ? "bold" : "" }}
                onClick={() => handleLanguageChange("en")}
            >
              EN
            </span>
        </div>
    )
};

export default LanguageSwitcher;
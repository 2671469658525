const preWeddingInfo = [
    {
        title: "WELCOME_DRINKS",
        location: "WELCOME_DRINKS_LOCATION",
        time: "19:45 - 23 PM",
        address: "https://maps.app.goo.gl/PPhifmkM7n3GL9V77",
        user: ['admin', 'en']
    }
];

export { preWeddingInfo };

import React from 'react';
import { Trans } from 'react-i18next';

import Headline from 'components/UI/headline/Headline';
import Paragraph from 'components/UI/paragraph/Paragraph';
import Button from 'components/UI/button/Button';

import styling from './SectionTravel.module.scss';

const SectionTravel = ({
  id,
  title,
  section,
  text,
  extraText,
  isBulletPoint,
}) => {
  const handleOnButtonClick = () => {
    window.open(
      'https://www.google.com/maps/d/embed?mid=1HNRvSBX2a52FMzq0EUMfzdVx8GS2cCg&ehbc=2E312F',
      '_blank',
      'rel=noopener noreferrer'
    );
  };

  return (
    <div id={id} className={styling.container}>
      <Headline level={3} classStyle={styling.section}>
        <Trans>{section}</Trans>
      </Headline>

      {/* {title && <p className={styling.title}>{title}</p>} */}
      <Paragraph level={2} classStyle={extraText ? styling.text_extra : styling.text}>
        <Trans>{text}</Trans>
      </Paragraph>

        <div className={styling.extra}>
          {extraText &&
            isBulletPoint &&
            extraText.map((bulletPoint, index) => {
              return (
                <p key={index} className={styling.bulletPoint}>
                  <strong>{bulletPoint.title}</strong>{' '}
                  <Trans>{bulletPoint.text}</Trans>
                </p>
              );
            })}
        </div>
      {extraText &&
        !isBulletPoint &&
        extraText.map((bulletPoint, index) => {
          return (
              <div className={styling.extraContent} key={index} >
                <p key={index} className={styling.bulletPoint}>
                  <strong>
                    <Trans>{bulletPoint.title}</Trans>
                  </strong>
                </p>

                <Button
                  buttonText={'OPEN_THE_MAP'}
                  onClick={handleOnButtonClick}
                />
              </div>
          );
        })}
    </div>
  );
};

export default SectionTravel;

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Headline from 'components/UI/headline/Headline';
import Paragraph from 'components/UI/paragraph/Paragraph';

import styling from './Section.module.scss';

const Section = ({ id, question, answer, link }) => {
    const { t } = useTranslation();

  return (
    <div id={id} className={styling.container}>
      <Headline level={3} classStyle={styling.headline}>
        <Trans>{question}</Trans>
      </Headline>

      <Paragraph level={2} classStyle={styling.answer}>
          <div dangerouslySetInnerHTML={{ __html: t(answer) }} />
      </Paragraph>
    </div>
  );
};

export default Section;

import React from 'react';

import styling from './Headline.module.scss';

const Headline = ({ children, level, classStyle = '' }) => {
  return (
    <div
      className={`${classStyle} ${styling[`level${level}`] || styling.level1}`}
    >
      <div className={styling.label}>{children}</div>
    </div>
  );
};

export default Headline;

import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Loading } from './Loading';
import TopBar from 'components/layout/topBar/TopBar';
import Footer from 'components/layout/footer/Footer';
import axios from 'axios';

import styling from './Authenticated.module.scss';

const Authenticated = ({ user, currentLanguage, setCurrentLanguage }) => {
  const [isValidToken, setIsValidToken] = useState(null);
  const navigate = useNavigate();

  const validateToken = async (token) => {
    const { data } = await axios.post('/requireLogIn', {
      token: token,
    });

    if (data.isValid === true) {
      setIsValidToken(true);
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    const auth = localStorage?.getItem('auth');
    const authJson = JSON.parse(auth);

    const token = authJson?.token;
    const expirationDate = authJson?.expirationDate;

    if (token && expirationDate) {
      if (new Date(expirationDate) < new Date()) {
        localStorage?.removeItem('auth');
        setIsValidToken(false);
        navigate('/login');
      } else {
        validateToken(token);
      }
    }
  }, [setIsValidToken]);

  const content = (
    <div className={styling.container}>
      <main className={styling.main}>
        <TopBar
          userType={user}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
        />

        <Outlet />

        <Footer />
      </main>
    </div>
  );

  return <>{isValidToken ? content : <Loading />}</>;
};

export { Authenticated };

import React from 'react';
import { Trans } from 'react-i18next';

import styling from './Togge.module.scss';

const Toggle = ({
  label,
  checked,
  required,
  onClick,
  position = 'horizontal',
  hidden,
}) => {
  let containerStyle = styling.container;

  if (position === 'vertical') {
    containerStyle += ' ' + styling.vertical;
  }

  // Determine styling
  let onClassName = styling.on;
  let offClassName = styling.off;

  if (checked) {
    onClassName += ' ' + styling.selected;
  } else {
    offClassName += ' ' + styling.selected;
  }

  return (
    <div className={containerStyle} hidden={hidden}>
      <span className={styling.label}>
        {required && <span className={styling.asterisk}>*</span>}

        {label}
      </span>

      <div className={styling.options}>
        <div className={onClassName} onClick={onClick}>
          <Trans>{"RSVP_YES"}</Trans>
        </div>

        <div className={styling.divider} />

        <div className={offClassName} onClick={onClick}>
          <Trans>{"RSVP_NO"}</Trans>
        </div>
      </div>
    </div>
  );
};

export default Toggle;
